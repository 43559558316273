<template>
  <v-app :class="[colorTheme, calcedBgColor]">
    <transition
      name="fade"
      mode="out-in"
    > 
      <router-view/>
    </transition>  
    <Nav v-bind:color-theme="colorTheme" v-bind:bg-color="bgColor" v-bind:calced-bg-color="calcedBgColor"></Nav>
  </v-app>
</template>

<script>
  import Nav from '@/components/Nav.vue'
  export default {
    data: function () {
      return {
        colorTheme: 'light-mode',
        bgColor: 'blue-grey'
      }
    },
    components: {
      Nav
    },
    computed: {
      calcedBgColor: function () {
        if (this.colorTheme === 'light-mode') {
          return 'lighten-5 ' + this.bgColor;
        } else {
          return 'darken-4 ' + this.bgColor;
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "./styles/global.scss";
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .3s ease-out, transform .3s ease-out;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }

.fade-enter, .fade-leave-to {
    transform: translateY(-12px);    
  }

  
  #app {
    min-height: 100vh;
    position: absolute;
    width: 100%;
    display: flex;
    &.dark-mode {
      &:after {
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: rgba(0,0,0,.5);
        top: 0;
      }
    }
  }
</style>