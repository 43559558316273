<template>
  <div>
    <button id="toggleNav" @click="toggleNav()" :class="{ isEx: !hideNav }">
      <span class="bar top" :class="colorTheme"></span>
      <span class="bar center" :class="colorTheme"></span>
      <span class="bar bottom" :class="colorTheme"></span>
    </button>
    <div class="overlay" :class="[{ offCanvas: hideNav}, colorTheme]" @click="toggleNav()">

    </div>
    <div :class="[{ offCanvas: hideNav}, calcedNavBgColor]" class="nav__wrapper">
      <nav id="nav">
        <router-link to="/work" v-on:click.native="toggleNav()" class="nav-link">
          Work
        </router-link> 
        <router-link to="/resume" v-on:click.native="toggleNav()" class="nav-link">
          Resume
        </router-link> 
        <router-link to="/contact" v-on:click.native="toggleNav()" class="nav-link">
          Contact
        </router-link>
        <router-link to="/" v-on:click.native="toggleNav()" class="nav-link">
          Home
        </router-link>
      </nav>
      <div class="toggle__wrapper">
        <button @click="toggleColorTheme()" :class="colorTheme" class="theme-selector">
        </button>
        <span class="color-selector-trigger" @click="colorNavHidden = !colorNavHidden">
          <ul :class="{flipped: colorNavHidden }">
            <li class="red-400"></li>
            <li class="pink-400"></li>
            <li class="purple-400"></li>
            <li class="deep-purple-400"></li>
            <li class="indigo-400"></li>
            <li class="blue-400"></li>
            <li class="light-blue-400"></li>
            <li class="cyan-400"></li>
            <li class="teal-400"></li>
            <li class="green-400"></li>
            <li class="light-green-400"></li>
            <li class="lime-400"></li>
            <li class="yellow-400"></li>
            <li class="amber-400"></li>
            <li class="orange-400"></li>
          </ul>
        </span>
        <div class="bg-color-selectors" :class="{hidden: colorNavHidden}">
          <span class="bg-selector blue-grey-400" :class="colorTheme" @click="changeBgColor('blue-grey')"></span>
          <span class="bg-selector red-400" :class="colorTheme" @click="changeBgColor('red')"></span>
          <span class="bg-selector pink-400" :class="colorTheme" @click="changeBgColor('pink')"></span>
          <span class="bg-selector purple-400" :class="colorTheme" @click="changeBgColor('purple')"></span>
          <span class="bg-selector deep-purple-400" :class="colorTheme" @click="changeBgColor('deep-purple')"></span>
          <span class="bg-selector indigo-400" :class="colorTheme" @click="changeBgColor('indigo')"></span>
          <span class="bg-selector blue-400" :class="colorTheme" @click="changeBgColor('blue')"></span>
          <span class="bg-selector light-blue-400" :class="colorTheme" @click="changeBgColor('light-blue')"></span>
          <span class="bg-selector cyan-400" :class="colorTheme" @click="changeBgColor('cyan')"></span>
          <span class="bg-selector teal-400" :class="colorTheme" @click="changeBgColor('teal')"></span>
          <span class="bg-selector green-400" :class="colorTheme" @click="changeBgColor('green')"></span>
          <span class="bg-selector light-green-400" :class="colorTheme" @click="changeBgColor('light-green')"></span>
          <span class="bg-selector lime-400" :class="colorTheme" @click="changeBgColor('lime')"></span>
          <span class="bg-selector yellow-400" :class="colorTheme" @click="changeBgColor('yellow')"></span>
          <span class="bg-selector amber-400" :class="colorTheme" @click="changeBgColor('amber')"></span>
          <span class="bg-selector orange-400" :class="colorTheme" @click="changeBgColor('orange')"></span>
          <span class="bg-selector deep-orange-400" :class="colorTheme" @click="changeBgColor('deep-orange')"></span>
        </div>
      </div>  
    </div>
  </div>
</template>

<script>
  export default {
    data: function () {
      return {
        hideNav: true,
        isMobile: true,
        colorNavHidden: true
      }
    },
    props: {
      colorTheme: String,
      calcedBgColor: String,
      bgColor: String
    },
    methods: {
      toggleNav () {
        this.hideNav = !this.hideNav;
        if (this.colorNavHidden === false) {
          this.colorNavHidden = true;
        }
      },
      toggleColorTheme () {
        if ( this.$parent.$parent.colorTheme == 'light-mode' ) {
          this.$parent.$parent.colorTheme = 'dark-mode'
        } else {
          this.$parent.$parent.colorTheme = 'light-mode'
        }
        if (this.isMobile) {
          // this.hideNav = !this.hideNav;
        }
      },
      changeBgColor (color) {
        this.$parent.$parent.bgColor = color;
        if (this.isMobile) {
          // this.hideNav = !this.hideNav;
        }
      }
    },
    mounted: function() {
      this.$nextTick(function () {
        if ( window.innerWidth >= 768){
          this.isMobile = false;
        } else {
          this.isMobile = true;
        }
      })
    },
    computed: {
      calcedNavBgColor: function () {
        if (this.$parent.$parent.colorTheme === 'light-mode') {
          return 'lighten-4 ' + this.$parent.$parent.bgColor;
        } else {
          return 'darken-4 ' + this.$parent.$parent.bgColor;
        }
      }
    }
  }
</script>


<style lang="scss">
  @import "./../styles/global.scss";

  .overlay {
    position: fixed;
    top: 0; 
    left: 0;
    height: 100%;
    opacity: 1;
    transition: opacity .3s ease-out;
    width: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 3;

    &.light-mode {
      background-color: rgba(255,255,255,.5);
    }

    &.offCanvas {
      left: -100%;
      box-shadow: none;
      opacity: 0;
    }
    @media screen and (min-width: $sm-break){
      width: 100%;
    }
  }

  .nav__wrapper {
    position: fixed;
    top: 0; 
    right: 0;
    height: 100%;
    transition: right .3s ease-out;
    box-shadow: 0 0 16px 8px rgba(0,0,0,0.1);
    width: 100%;

    @media screen and (min-width: $sm-break){
      width: 50%;
    }

    &.offCanvas {
      right: -100%;
      box-shadow: none;
    }
  }

  button.theme-selector {
    transition: left .3s ease-out;
    position: absolute;
    right: 48px;
    bottom: 0;
    &:after {
      transition: left .3s ease-out;
    }
    width: 48px;
    height: 24px;
    border-radius: 12px;
    border: 2px transparent solid;

    &.light-mode {
      background-color: $grey;

      &:after {
        content: "";
        display: block;
        height: 18px;
        width: 18px;
        position: absolute;
        top: 1px;
        left: 1px;
        background-color: $white;
        border-radius: 50%;
      }
    }

    &.dark-mode {
      background-color: $dark-grey;
      
      &:after {
        content: "";
        display: block;
        height: 18px;
        width: 18px;
        position: absolute;
        top: 1px;
        left: 25px;
        background-color: $white;
        border-radius: 50%;
      }
    }
  }

  .color-selector-trigger {
    position: absolute;
    right: 0;
    bottom: -6px;
    height: 36px;
    width: 36px;
    z-index: 5;
    cursor: pointer;
    ul {
    display: flex;
    flex-direction: column;
    transition: all .3s ease-out;
    transform: rotateZ(0deg);
    &.flipped {
      transform: rotateZ(-180deg);
      // flex-direction: column-reverse;
    }
    height: 36px;
      padding: 0;
      clip-path: circle(18px at center);
      li {
        height: 6.6666%;
        width: 100%;
      }
    }
  }

  .bg-color-selectors {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: -72px;
    left: 24px;
    height: 100vh;
    transition: height .3s ease-out;
    flex-direction: column;

    &.hidden {
      height: 0;
    }

    @media screen and (min-width: $sm-break){
      width: 100%;
      flex-direction: row;
      right: -24px;
      left: auto;
      bottom: -72px;
      height: 48px;
      transition: right .3s ease-out;
      &.hidden {
        right: calc(-100% - 24px);
        height: 48px;
      }
    }


    .bg-selector {
      cursor: pointer;
      width: 48px;
      height: inherit;
      @media screen and (min-width: $sm-break){
        height: 48px;
        width: inherit;
      }
    }
  }

  #toggleNav {
    z-index: 4;
    position: absolute;
    right: 24px;
    top: 24px;
    background-color: transparent;
    border: none;
    height: 18px;
    width: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .bar {
      transition: all .15s ease-out;
      height: 2px;
      width: 24px;
      background-color: $dark-grey;
      &.dark-mode {
        background-color: $white;
      }
    }

    &.isEx {
      justify-content: center;
      .bar.center {
        display: none;
      }
      .bar.top {
        transform: rotateZ(45deg);
        transform-origin: calc(50% - 1px);
      }
      .bar.bottom {
        transform: rotateZ(-45deg);
        transform-origin: calc(50% - 1px);
      }
    }
  }

  .toggle__wrapper {
    position: absolute;
    right: 24px;
    bottom: 72px;
    z-index: 3;
    width: 100%;
  }

  #nav {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
</style>

    